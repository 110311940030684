export const copyFooter = {
  links: [
    {
      title: "King Alfonso",
      indLinks: [
        {
          copy: "Over King Alfonso",
          url: "/",
        },
        {
          copy: "Over diensten",
          url: "/",
        },
        {
          copy: "Contact",
          url: "/",
        },
      ],
    },
    {
      title: "Sociale Media",
      indLinks: [
        {
          copy: "LinkedIn",
          url: "/",
        },
        {
          copy: "Email",
          url: "/",
        },
      ],
    },
    {
      title: "Waar kan u ons vinden?",
      className: "no-link",
      indLinks: [
        {
          copy: "Mechelsesteenweg 127",
        },
        {
          copy: "2018 Antwerpen",
        },
      ],
    },
  ],
  subLinks: [
    {
      image: true,
      copy: "logo King Alfonso",
      url: "/",
    },
    {
      copy: "Privacy policy",
      url: "/privacy-policy",
    },
    {
      copy: "Cookiebeleid",
      url: "/",
    },
    {
      copy: "Alle rechten voorbehouden Kris Heeren 2013",
    },
    {
      copy: "Website door Huis Heeren",
    },
  ],
};
