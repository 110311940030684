import "../../styles/_typography.scss";

const MailTo = ({ email, subject, ...props }) => {
  return (
    <a
      className="button bttn-gold-bg"
      href={`mailto:${email}?subject=${subject || ""}`}
    >
      {props.children}
    </a>
  );
};

export default MailTo;
