import { copyWebsite } from "../../json/copyWebsite";
import { _images } from "../../_utils/_images";
import "./contact.scss";

const Contact = () => {
  const copy = copyWebsite.contact;
  const img = _images.contact.CONTACT;

  return (
    <div className="contact-page">
      <div className="contact__content">
        <div className="content__header">
          <h1>{copy.title}</h1>
          <h2>{copy.subtitle}</h2>
          <button>{copy.button}</button>
        </div>
        <div className="content__addit">
          <div className="contact-detail-ic det-calendar-ic">
            <div>
              <p className="contact-detailed-text">{copy.adres_straat}</p>
              <p className="contact-detailed-text">{copy.adres_postcode}</p>
            </div>
          </div>
          <div className="contact-detail-ic det-phone-ic">
            <div>
              <p className="contact-detailed-text">{copy.email}</p>
              <p className="contact-detailed-text">{copy.phone}</p>
            </div>
          </div>
        </div>
      </div>
      <img src={img} alt="address King Alfonso" />
    </div>
  );
};

export default Contact;
