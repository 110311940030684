import "./hamburger.scss";

const Hamburger = ({ handleHamburger }) => {
  return (
    <div
      className="navigation__hamburger-menu"
      onClick={() => handleHamburger()}
    >
      <div className="burger burger1"></div>
      <div className="burger burger2"></div>
      <div className="burger burger3"></div>
    </div>
  );
};

export default Hamburger;
