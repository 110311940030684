import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const textGenerator = (value) => {
  console.log(value);
  if (value !== undefined) {
    return documentToReactComponents(value);
  }
};

export default textGenerator;
