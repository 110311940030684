export const copyWebsite = {
  home: {
    title: "King Alfonso",
    subtitle: "Human Resources Consultancy",
    intro:
      "King Alfonso biedt als human resources consultant een uitgebreide waaier aan diensten voor de social profit en profit sector, voor werkgevers én werknemers",
    button_vacatures: "Bekijk onze vacatures",
    button_diensten: "Bekijk onze diensten",
  },
  vacatures: {
    title: "Vacatures",
    results: "resultaten",
    info_title: "Wenst u meer informatie over onze recruitment procedure?",
    info_content:
      "De recruitment procedure wordt in alle integriteit en vertrouwen behandeld, respecterende de ethische richtlijnen. Indien u meer informatie wenst over deze procedure en de maatregelen kan u steeds contact opnemen met King Alfonso.",
    info_button: "Neem contact op",
  },
  detail: {
    button: "Solliciteer voor deze vacature",
    totenMet: "Kandidaturen tot",
    deadline: "Deadline",
    headline1: "De vacature",
    headline2: "Wat houdt deze functie in voor jou?",
    headline3: "Wat verwachten we van jou?",
    headline4: "Ons aanbod?",
    headline5: "Is dit uw job?",
    downloadTitle: "Download de advertentie",
    downloadSubtext: "Download hier",
    shareTitle: "Deel deze vacature",
  },
  contact: {
    title: "Contact",
    subtitle:
      "Het kantoor van King Alfonso bevindt zich in de gebouwen van Dunden te Antwerpen.",
    button: "Contacteer ons",
    adres_straat: "Lange Gasthuisstraat 29-31",
    adres_postcode: "2000 Antwerpen",
    email: "kris@kingalfonso.be",
    phone: "+32 471 81 60 08",
  },
};
