import { Routes, Route, useLocation } from "react-router-dom";
import { useState } from "react";

import Navigation from "./components/navigation/Navigation";
import Home from "./pages/home/Home";
import Vacatures from "./pages/vacatures/Vacatures";
import Detail from "./pages/detail/Detail";
import Contact from "./pages/contact/Contact";
import Footer from "./components/footer/Footer";
import Privacy from "./pages/privacy/Privacy";

import "./App.scss";
import "./styles/_typography.scss";

function App() {
  const [hamburger, toggleHamburger] = useState(false);
  const location = useLocation();
  const isDetail = location.pathname.includes("/vacatures/");

  const handleHamburger = () => {
    toggleHamburger(!hamburger);
  };

  return (
    <div
      className={`App 
      ${isDetail && "App-bg-white"} 
      ${location.pathname === "/" && "home-page-app"}
      ${hamburger && "hamburger-display"}
      `}
    >
      <h1>King Alfonso</h1>
      <header className={`App-header header-width`}>
        <Navigation hamburger={hamburger} toggleHamburger={handleHamburger} />
      </header>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/vacatures" element={<Vacatures />} />
        <Route path="/vacatures/:id" element={<Detail />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<Privacy />} />
      </Routes>
      {location.pathname !== "/" && <Footer />}
    </div>
  );
}

export default App;
