import { createClient } from "contentful";

const useContentfulData = () => {
  const client = createClient({
    space: process.env.REACT_APP_SPACE_ID,
    accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    host: process.env.REACT_APP_HOST,
  });

  const getVacatures = async () => {
    try {
      const entries = await client.getEntries({
        content_type: "vacature",
        select: "fields",
      });
      return entries;
    } catch (error) {
      console.log(`Error fetching authors: ${error}`);
    }
  };

  const getPrivacy = async () => {
    try {
      const entry = await client.getEntry("7jvjqVda9hNd65c3dmRT6N");
      return entry;
    } catch (error) {
      console.log(`Error fetching authors: ${error}`);
    }
  };

  return { getVacatures, getPrivacy };
};

export default useContentfulData;
