import { copyFooter } from "../../json/copyFooter";
import { _images } from "../../_utils/_images";
import { Link } from "react-router-dom";

import "./footer.scss";

const Footer = () => {
  const renderImage = (el) => {
    return el.image ? (
      <img alt={el.copy} src={_images.footer.KINGALFONSOLOGO} />
    ) : (
      el.copy
    );
  };

  return (
    <footer>
      <h2>Footer</h2>
      <div className="footer__container">
        <div className="footer__links">
          {copyFooter.links.map((el, i) => {
            return (
              <div key={i}>
                <h3>{el.title}</h3>
                {el.indLinks.map((link, j) => {
                  return link.url ? (
                    <Link key={j} to={link.url}>
                      {link.copy}
                    </Link>
                  ) : (
                    <p key={j}>{link.copy}</p>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="footer__line"></div>
        <div className="footer__sublinks">
          {copyFooter.subLinks.map((el, i) => {
            return el.url ? (
              <Link to={el.url} key={i}>
                {renderImage(el)}
              </Link>
            ) : (
              <p key={i}>{el.copy}</p>
            );
          })}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
