import React from "react";
import "./home.scss";
import { copyWebsite } from "../../json/copyWebsite";
import { _images } from "../../_utils/_images";
import { Link } from "react-router-dom";

const Home = () => {
  const text = copyWebsite.home;
  const img = _images.home;

  return (
    <div className="home-page">
      <div className="home-page__content">
        <h1>{text.title}</h1>
        <h2>{text.subtitle}</h2>
        <p>{text.intro}</p>
        <div className="home-page__button-container">
          <Link to="/vacatures" className="button">
            {text.button_vacatures}
          </Link>
        </div>
      </div>
      <img src={img.HOME_IMAGE} alt="shapes and colours with profile of Kris" />
    </div>
  );
};

export default Home;
