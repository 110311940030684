import React from "react";
import { NavLink } from "react-router-dom";
import "./navigation.scss";
import { _images } from "../../_utils/_images";
import Hamburger from "../hamburger/Hamburger";

const Navigation = ({ hamburger, toggleHamburger }) => {
  return (
    <nav>
      <ul>
        <li>
          <NavLink to="/">
            <img src={_images.KINGALFONSOLOGO} alt="Logo King Alfonso" />
          </NavLink>
        </li>
        <Hamburger handleHamburger={toggleHamburger} />
        <div
          className={`list-item__group ${hamburger ? "hamburger-open" : ""}`}
        >
          <li>
            <NavLink
              to="/"
              onClick={toggleHamburger}
              className={({ isActive }) =>
                "list-item__NavLink" + (isActive ? "-active" : "")
              }
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/vacatures"
              onClick={toggleHamburger}
              className={({ isActive }) =>
                "list-item__NavLink" + (isActive ? "-active" : "")
              }
            >
              Vacatures
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/contact"
              onClick={toggleHamburger}
              className={({ isActive }) =>
                "list-item__NavLink" + (isActive ? "-active" : "")
              }
            >
              Contact
            </NavLink>
          </li>
        </div>
      </ul>
    </nav>
  );
};

export default Navigation;
