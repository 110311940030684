import { useState, useEffect } from "react";
import useContentfulData from "../../_utils/_useContentfulData";
import textGenerator from "../../_utils/_textGenerator";

import "./privacy.scss";

const Privacy = () => {
  const [privacy, setPrivacy] = useState();
  const { getPrivacy } = useContentfulData();

  useEffect(() => {
    getPrivacy().then((response) => setPrivacy(response));
  }, [getPrivacy]);

  return privacy ? (
    <div className="privacy-policy-page">
      <section className="privacy-policy-section">
        {textGenerator(privacy.fields.privacyPolicy)}
      </section>
    </div>
  ) : (
    <></>
  );
};

export default Privacy;
