import { NavLink, useLocation } from "react-router-dom";
import { copyWebsite } from "../../json/copyWebsite";
import { _images } from "../../_utils/_images";
import MailTo from "../../components/mailTo/MailTo";
import "./detail.scss";
import textGenerator from "../../_utils/_textGenerator";

const Detail = () => {
  const location = useLocation();
  const vacature = location.state.vacature;
  const img = _images.detail;
  const copy = copyWebsite.detail;

  return (
    <>
      <div className="detail-page header-width">
        <NavLink to="/vacatures">
          <img
            src={img.GO_BACK}
            className="detail-go-back"
            alt="arrow to the left"
          />
        </NavLink>
        <div className="detail-heading">
          <h2 className="job h1-title">{vacature.title}</h2>
          <h3 className="client">{vacature.client}</h3>

          <MailTo email="kris@kingalfonso.be" subject={vacature.title}>
            {copy.button}
          </MailTo>
          <div className="detailed-info-container">
            <p className="detailed-info-text location-icon">{vacature.place}</p>
            <p className="detailed-info-text calendar-icon">
              {copy.totenMet} {vacature.deadline}
            </p>
          </div>
        </div>
      </div>
      <div className="description-container">
        <div className="description">
          <section>
            <h3 className="detail-h3">{copy.headline1}</h3>
            {textGenerator(vacature.intro)}
          </section>
          <section>
            <h3 className="detail-h3">{copy.headline2}</h3>
            {textGenerator(vacature.profiel)}
          </section>
          <section>
            <h3 className="detail-h3">{copy.headline3}</h3>
            {textGenerator(vacature.functie)}
          </section>
          <section>
            <h3 className="detail-h3">{copy.headline4}</h3>
            {textGenerator(vacature.aanbod)}
          </section>
          <section>
            <h3 className="detail-h3">{copy.headline5}</h3>
            {textGenerator(vacature.interesse)}
          </section>
          <section>
            <MailTo email="kris@kingalfonso.be" subject={vacature.title}>
              {copy.button}
            </MailTo>{" "}
            <p className="small-info-text">
              {copy.deadline} {vacature.deadline}
            </p>
          </section>
        </div>
        <div className="detail-page-share">
          <div className="share-info">
            <h3 className="detail-h3">{copy.downloadTitle}</h3>
            <button>{copy.downloadSubtext}</button>
          </div>
          <div className="share-info">
            <h3 className="detail-h3">{copy.shareTitle}</h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default Detail;
