export const _images = {
  KINGALFONSOLOGO: require("../assets/images/king_alfonso_logo.svg").default,

  home: {
    HOME_IMAGE: require("../assets/images/home_image.png"),
  },

  detail: {
    GO_BACK: require("../assets/icons/detail_back.svg").default,
  },

  contact: {
    CONTACT: require("../assets/images/contact.png"),
  },

  footer: {
    KINGALFONSOLOGO: require("../assets/images/ka_logo_footer.svg").default,
  },
};
