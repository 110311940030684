import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { copyWebsite } from "../../json/copyWebsite";
import "./vacatures.scss";
import useContentfulData from "../../_utils/_useContentfulData";

const Vacatures = () => {
  const text = copyWebsite.vacatures;
  const [vacatures, setVacatures] = useState();
  const { getVacatures } = useContentfulData();

  useEffect(() => {
    console.log("helo");
    getVacatures().then((response) => setVacatures(response));
  }, []);

  return (
    <div className="vacatures-page">
      <section className="section__vacatures">
        <h1>{text.title}</h1>
        <p className="vacatures-page__results">
          {vacatures && vacatures.total}
          {` ${text.results}`}
        </p>
        <ul>
          {vacatures && console.log(vacatures.items[0].fields)}
          {vacatures &&
            vacatures.items.map((fields, index) => {
              const vacature = fields.fields;
              return (
                <li key={index}>
                  <Link
                    to={`/vacatures/${vacature.title}`}
                    state={{ vacature: vacature }}
                  >
                    <h2>{vacature.title}</h2>
                    <h3>{vacature.client}</h3>
                    <div className="vacatures-page__details">
                      <p className="vacatures-page__place">{vacature.place}</p>
                      <div className="grey-circle"></div>
                      <p className="vacatures-page__calendar">
                        Kandidaturen tot {vacature.deadline}
                      </p>
                    </div>
                  </Link>
                </li>
              );
            })}
        </ul>
      </section>
      <section className="section__more-info">
        <div>
          <h2>{text.info_title}</h2>
          <p>{text.info_content}</p>
          <button>{text.info_button}</button>
        </div>
      </section>
    </div>
  );
};

export default Vacatures;
